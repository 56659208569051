import * as React from "react"
//import { Link, graphql } from "gatsby"
import { graphql } from "gatsby"
//import { Link } from "../utils/link"
import { Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPostCard from "../components/blog-post-card"

const BlogIndex = ({ data, location }) => {
  const {t, i18n} = useTranslation()
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const notes = data.allFeednote.nodes;
  const langKey = i18n.language || `ja`;

  // parse note's datetime (Sun, 09 Jan 2022 22:14:04 +0900) as Date, then slice it to fit Gatsby Date(2022-05-14)
  const _notes = notes.map(v => {
    v.date = new Date(v.pubDate).toISOString().slice(0, 10);
    return v;
  });

  const _items = _notes.concat(posts).sort((x, y) => {
    const xd = x.date ? x.date : x.frontmatter.date;
    const yd = y.date ? y.date : y.frontmatter.date;
    if(xd < yd) return -1;
    if(xd > yd) return  1;
    return 0;
  }).reverse();

  const tags = data.tags.tags;

  if (_items.length === 0) {
    return (
      <Layout location={location} title={siteTitle} tags={tags}>
        <Seo
          title={t("NO PREFIX NOTES")}
          lang={langKey}
        />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} tags={tags}>
      <Seo
        title={t("NO PREFIX NOTES")}
        lang={langKey}
      />
      <ol style={{ listStyle: `none` }}>
        {_items.map(post => {
          return (
            <li key={post.fields?.slug ?? post.link}>
              <BlogPostCard data={post} />
            </li>)
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

// checking lang equality
export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    tags: allMarkdownRemark(
      limit: 2000
        filter: {
          fields: {langKey: {eq: $language}},
        }) {
        tags: group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    allFeednote {
      nodes {
        link
        title
        pubDate
        description
      }
    }
    allMarkdownRemark(
      filter: {fields: {langKey: {eq: $language}}},
      sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          excerpt
          fields {
            slug
            langKey
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
    }
  }
`
